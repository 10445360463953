
//公共库
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { getSuperviseRiskImList, getSuperviseRiskImLabelList } from "@/api/request/supervise";

//组件
@Component({
  name: "IM",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //传入数据
  @Prop({ default: 0 }) private user_id!: number;

  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private options: any[] = [];
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //临时数据
    time: "",

    //请求数据
    end_time: "",
    start_time: "",
    risk_label: "",
    userid: undefined,
    bloggerid: undefined,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //数据赋值
    this.listQuery.risk_label = "porn";
    this.listQuery.userid = this.user_id;

    //初始化时间
    this.initTime();

    //获取类型
    this.getSuperviseType();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);
    if (this.listQuery.bloggerid) this.listQuery.bloggerid = Number(this.listQuery.bloggerid);

    //获取数据
    const { data } = await getSuperviseRiskImList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD30()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
    this.listQuery.start_time += "  00:00:00";
    this.listQuery.end_time += "  23:59:59";
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //清空数据
    this.listQuery.risk_label = "";
    this.listQuery.userid = undefined;
    this.listQuery.bloggerid = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  @Watch("user_id")
  private onChangeUserID(): void {
    //数据赋值
    this.listQuery.risk_label = "porn";
    this.listQuery.userid = this.user_id;

    //获取数据
    this.getList();
  }

  //类型
  private typeFormat(row: any) {
    if (row.msg_type === "RC:TxtMsg") return "文本消息";
    if (row.msg_type === "RC:ImgMsg") return "图片消息";
    if (row.msg_type === "RC:VcMsg") return "语音消息";
    if (row.msg_type === "RC:HQVCMsg") return "高清语音消息";
    if (row.msg_type === "RC:SightMsg") return "小视频消息";
  }

  //获取类型
  private async getSuperviseType() {
    //显示等待
    this.listLoading = true;

    //获取类型
    const res = await getSuperviseRiskImLabelList();

    //数据赋值
    this.options = res.data;
  }

  //类型修改
  private typeChange(val: any): void {
    //数据赋值
    this.listQuery.risk_label = val;
  }

  //显示内容
  private showSightDetail(url: any): void {
    //数据赋值
    this.dialogUrl = url;

    //显示内容
    this.showDialog = true;
  }

  //--------------------------------- 显示内容 ---------------------------------
  //定义变量
  private dialogUrl: string = "";
  private showDialog: boolean = false;
}
