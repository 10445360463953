
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import IM from "./im/index.vue";
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import { Message, MessageBox } from "element-ui";
import Pagination from "@/comp/Pagination/index.vue";
import { getUserRiskList, saveUserRisk, removeUserRisk, exportUserRiskList, auditUserRisk } from "@/api/request/user";

//组件
@Component({
  name: "UserRisk",
  components: {
    IM,
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = true;

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 0,
    page_size: 20,

    //临时数据
    time: [],
    risk_time: [],
    user_id_str: "",

    //请求数据
    sex: -1, //性别
    user_id: 0, //用户ID
    user_from: 0, //用户来源
    mark_type: 0, //标记来源
    country: "", //归属国家
    audit_status: -1, //审核状态

    //时间参数
    edn_time: "", // 标记结束时间，格式：2024-08-27
    start_time: "", // 标记开始时间，格式：2024-08-27
    risk_end_time: "", // 风险记录结束时间，格式：2024-08-27
    risk_start_time: "", // 风险记录开始时间，格式：2024-08-27
  };

  //日期数据
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //性别列表
  private sexList: any[] = [
    { sex: -1, name: "全部性别" },
    { sex: 1, name: "男" },
    { sex: 2, name: "女" },
  ];

  //用户来源列表
  private userFromList: any[] = [
    { from: 0, name: "全部用户来源" },
    { from: 1, name: "广告来源" },
    { from: 2, name: "自然流量" },
  ];

  //标记来源列表
  private markTypeList: any[] = [
    { type: 0, name: "全部来源" },
    { type: 1, name: "自动标记" },
    { type: 2, name: "无交互标记" },
    { type: 3, name: "后台手动标记" },
    { type: 4, name: "无充值" },
  ];

  //审核状态列表
  private auditStatusList: any[] = [
    { status: -1, name: "全部审核状态" },
    { status: 0, name: "待审核" },
    { status: 1, name: "审核通过" },
    { status: 2, name: "审核不通过" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]}`;
      this.listQuery.start_time = `${this.listQuery.time[0]}`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    if (this.listQuery.risk_time && this.listQuery.risk_time.length == 2) {
      this.listQuery.risk_end_time = `${this.listQuery.risk_time[1]}`;
      this.listQuery.risk_start_time = `${this.listQuery.risk_time[0]}`;
    } else {
      this.listQuery.risk_end_time = "";
      this.listQuery.risk_start_time = "";
    }
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await getUserRiskList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private addHandler(): void {
    //数据赋值
    this.addForm = {
      ids: [], //ID
      remark: "", //备注
    };
    this.tag = [];
    this.tagInput = "";
    this.tagInputMore = "";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]}`;
      this.listQuery.start_time = `${this.listQuery.time[0]}`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    if (this.listQuery.risk_time && this.listQuery.risk_time.length == 2) {
      this.listQuery.risk_end_time = `${this.listQuery.risk_time[1]}`;
      this.listQuery.risk_start_time = `${this.listQuery.risk_time[0]}`;
    } else {
      this.listQuery.risk_end_time = "";
      this.listQuery.risk_start_time = "";
    }
    this.listQuery.user_id = Number(this.listQuery.user_id);

    //获取数据
    const { data } = await exportUserRiskList(this.listQuery);

    //保存数据
    saveAs(data, "多巴用户列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理IM监管
  private handleIM(row: any): void {
    //数据赋值
    this.userID = row.user_id;

    //显示对话框
    this.imDialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await removeUserRisk({ id: row.user_id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //显示更多图片
  private handleMore(row: any): void {
    //显示更多
    this.openImg(row.images);
  }

  //获取图片
  private getImages(images: any): Array<string> {
    //返回数据
    var list: Array<string> = [];
    if (images) {
      for (var i: number = 0; i < Math.min(2, images.length); i++) {
        list.push(images[i]);
      }
    }
    return list;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //处理审核
  private async handleAuditStatus(row: any) {
    //数据赋值
    this.statusForm = {
      id: row.user_id,
      status: 0,
      remark: "",
    };

    //显示对话框
    this.statusDialogVisible = true;
  }

  //--------------------------------- 审核 ---------------------------------
  //定义变量
  private statusDialogVisible: boolean = false;

  //定义变量
  private statusForm: any = {
    id: 0,
    status: 0,
    remark: "",
  };

  //处理审核
  private async handleChangeStatus(status: number) {
    //判断返回
    if (status == 2 && this.statusForm.remark == "") return Message({ message: "请输入审核备注！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    this.statusForm.status = status;

    //修改审核状态
    await auditUserRisk(this.statusForm);

    //显示提示
    this.$message.success("审核成功");

    //隐藏对话框
    this.statusDialogVisible = false;

    //加载列表
    this.getList();
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private tag: string[] = [];
  private tagInput: string = "";
  private tagInputMore: string = "";
  private dialogVisible: boolean = false;

  //添加表单
  private addForm: any = {
    ids: [], //ID
    remark: "", //备注
  };

  //添加用户
  private async addData() {
    //判断返回
    if (this.tag.length == 0) return this.$message.error("请添加用户ID");
    if (this.addForm.remark == "") return Message({ message: "请输入备注", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.ids = [];
    for (var i: number = 0; i < this.tag.length; i++) {
      this.addForm.ids.push(Number(this.tag[i]));
    }

    //添加用户
    const data: any = await saveUserRisk(this.addForm);

    //隐藏界面
    this.dialogVisible = false;

    //判断显示
    if (data.status == 0) {
      //显示提示
      this.$notify({
        title: "成功",
        message: "添加成功",
        type: "success",
        duration: 2000,
      });
    } else if (data.status == 3) {
      //显示提示
      MessageBox.confirm(data.msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    }

    //获取列表
    this.getList();
  }

  //添加目标用户
  private addTag(type: any): void {
    //批量添加
    if (type === "more") {
      //定义变量
      let errorList: any = [];

      //数据处理
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());
          this.tag.push(item.trim());
          this.tagInputMore = ""; // 清空输入框
        });

      //显示异常提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //数据处理
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        this.tag.push(this.tagInput.trim());
        this.tagInput = ""; // 清空输入框
      }
      //异常处理
      else {
        this.$message.error("请输入正确形式的ID");
        this.tagInput = "";
      }
    }
  }

  //移除目标用户
  private removeTag(index: any): void {
    this.tag.splice(index, 1);
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;
  private moreDialogImageVisible = false;
  private moreImageList: string[] = [];

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }

  //显示更多图片
  private showMoreImage(urlList: string[]) {
    //数据赋值
    this.moreImageList = urlList;

    //显示界面
    this.moreDialogImageVisible = true;
  }

  //--------------------------------- 显示IM监管 ---------------------------------
  //定义变量
  private userID: number = 0;
  private imDialogVisible: boolean = false;
}
